import './App.css';
import RatingCard from './components/RatingCard/RatingCard';
function App() {
  return (
    <div className="App flex justify-center items-center h-screen bg-very-dark-blue">
      <RatingCard />
    </div>
  );
}

export default App;
